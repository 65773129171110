import { useTranslation } from 'react-i18next';
import { useAuth } from 'spindl-shared-components';

import { AUTH_ROUTES } from '../../../lib/constants';
import { getInitials } from '../../../lib/utils';
import { TopNavCombinedProps } from './types';
import { logout } from '../../../modules/auth/utils';

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { account } = useAuth();
  const { t } = useTranslation();

  const signOut = () => {
    logout();
    window.location.href = AUTH_ROUTES.signIn;
  };

  if (!account) {
    return props;
  }
  
  props = {
    ...props,
    accountMenuDropDown: {
      toggleProps: {
        initials: {
          text: {
            dataTestId: 'initials-text',
            value: getInitials(account.firstName, account.lastName),
          },
        },
        name: {
          dataTestId: 'name-text',
          value: `${account?.firstName} ${account?.lastName}`,
        },
      },
      menuProps: {
        dropDownItems: [
          {
            type: 'DefaultIconText',
            icon: {
              asset: 'SignOut',
              colour: 'IconDark',
            },
            text: {
              dataTestId: 'sign-out-button',
              value: t('top_nav.sign_out_button'),
            },
            onClick: signOut,
          },
        ],
      },
    },
  };

  return props;
};

export default usePresenter;
