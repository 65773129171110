import { getCurrentAppServices } from '../../../modules/services/services.repository';
import { SERVICES_ORDER } from '../../../lib/constants';

export const getCurrentAppServicesUseCase = async (activeAppId: number) => {
  const services = await getCurrentAppServices(activeAppId);

  const filtered = services.filter((service) => {
    return SERVICES_ORDER[service.serviceType.toLowerCase()];
  });

  filtered.sort((a, b) => (SERVICES_ORDER[a.serviceType.toLowerCase()] - SERVICES_ORDER[b.serviceType.toLowerCase()]) || a.serviceType.localeCompare(b.serviceType));
  return filtered;
};

