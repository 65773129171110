import React from 'react';
import cx from 'classnames';

import {
  TextField,
  Button,
} from 'spindl-shared-components';
import LogoWithHeader from '../../molecules/LogoWithHeader';

import styles from './SignInBlock.module.scss';
import usePresenter from './SignInBlock.presenter';
import getProps from './SignInBlock.props';
import { SignInBlockCombinedProps } from './types';


const SignInBlock: React.FC<SignInBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    logoWithHeader,
    emailField,
    passwordField,
    forgotPasswordButton,
    signInButton,
    signInForm,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signInBlock;

  return (
    <form 
      {...signInForm}
      className={cx(currentStyle, className)}>
      <LogoWithHeader
        className={cx(styles.logoWithHeader, classes?.logoWithHeader)}
        {...internalProps.logoWithHeader}
        {...logoWithHeader}
      />
      <TextField
        className={cx(styles.emailField, classes?.emailField)}
        {...internalProps.emailField}
        {...emailField}
        />
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <TextField
          className={cx(styles.passwordField, classes?.passwordField)}
          {...internalProps.passwordField}
          {...passwordField}
          />
        <Button
          className={cx(styles.forgotPasswordButton, classes?.forgotPasswordButton)}
          {...internalProps.forgotPasswordButton}
          {...forgotPasswordButton}
          />
      </div>
      <Button
        className={cx(styles.signInButton, classes?.signInButton)}
        {...internalProps.signInButton}
        {...signInButton}
        />
    </form>
  );
};

export default SignInBlock;
