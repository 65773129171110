import { useContext } from 'react';
import { MembersContext } from '../../../modules/member/MembersContext';
import { TeamsPageCombinedProps } from './types';

const usePresenter = (props: TeamsPageCombinedProps): TeamsPageCombinedProps => {
  const { modalState } = useContext(MembersContext);
  
  return {
    ...props,
    modalState,
  };
};

export default usePresenter;
