import {
  BorderProps,
  HorizontalTabListProps,
} from 'spindl-shared-components';


type SubNavInternalProps = {
  horizontalTabList?: HorizontalTabListProps;
  border?: BorderProps;
};

type SubNavPropsValues = {
  '': SubNavInternalProps;
};

const propValues: SubNavPropsValues = {
  '': {
    horizontalTabList: {
    },
    border: {
      colour: 'Subdued',
      type: 'Horizontal',
      styleType: 'Default',
    },
  },
};

const getProps = (type: string): SubNavInternalProps => {
  const values: SubNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
