import React from 'react';
import cx from 'classnames';

import { TextField, Button } from 'spindl-shared-components';
import LogoWithHeader from '../../molecules/LogoWithHeader';

import styles from './ResetPasswordBlock.module.scss';
import usePresenter from './ResetPasswordBlock.presenter';
import getProps from './ResetPasswordBlock.props';
import { ResetPasswordBlockCombinedProps } from './types';


const ResetPasswordBlock: React.FC<ResetPasswordBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    logoWithHeader,
    newPasswordField,
    confirmNewPasswordField,
    signUpButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.resetPasswordBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <LogoWithHeader
          className={cx(styles.logoWithHeader, classes?.logoWithHeader)}
          {...internalProps.logoWithHeader}
          {...logoWithHeader}
        />
        <TextField
          className={cx(styles.newPasswordField, classes?.newPasswordField)}
          {...internalProps.newPasswordField}
          {...newPasswordField}
        />
        <TextField
          className={cx(styles.confirmNewPasswordField, classes?.confirmNewPasswordField)}
          {...internalProps.confirmNewPasswordField}
          {...confirmNewPasswordField}
        />
      </div>
      <Button
        className={cx(styles.signUpButton, classes?.signUpButton)}
        {...internalProps.signUpButton}
        {...signUpButton}
      />
    </div>
  );
};

export default ResetPasswordBlock;
