import { InformationBlockProps } from '../../blocks/InformationBlock';


type ErrorPageInternalProps = {
  informationBlock?: InformationBlockProps;
};

type ErrorPagePropsValues = {
  '': ErrorPageInternalProps;
};

const propValues: ErrorPagePropsValues = {
  '': {
    informationBlock: {
    },
  },
};

const getProps = (type: string): ErrorPageInternalProps => {
  const values: ErrorPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
