import {
  ButtonProps,
  BorderProps,
  TextFieldProps,
  TextProps,
} from 'spindl-shared-components';


type TeammateInviteModalInternalProps = {
  title?: TextProps;
  closeButton?: ButtonProps;
  border?: BorderProps;
  emailField?: TextFieldProps;
  addButton?: ButtonProps;
};

type TeammateInviteModalPropsValues = {
  '': TeammateInviteModalInternalProps;
};

const propValues: TeammateInviteModalPropsValues = {
  '': {
    title: {
      align: 'Left',
      styleType: 'SemiBold',
      colour: 'Dark',
      type: 'Heading5',
    },
    closeButton: {
      type: 'IconNoBG',
      size: 'S',
      styleType: 'Primary',
      state: 'Default',
    },
    border: {
      colour: 'SubduedLight',
      type: 'Horizontal',
      styleType: 'Default',
    },
    emailField: {
      state: 'Default',
      type: 'Text',
      error: 'None',
    },
    addButton: {
      type: 'TextIcon',
      size: 'M',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): TeammateInviteModalInternalProps => {
  const values: TeammateInviteModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
