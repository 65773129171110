import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AUTH_ROUTES } from '../../../lib/constants';
import { replaceNewlines } from '../../../lib/reactUtils';
import { SignInPageCombinedProps } from './types';

const usePresenter = (props: SignInPageCombinedProps): SignInPageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBackToSignInButton = () => {
    history.push(AUTH_ROUTES.signIn);
  };

  props = {
    ...props,
    invalidInviteBlock: {
      logoWithHeader: {
        text: {
          value: t('invalid_invite.header'),
        },
        lowerText: {
          value: replaceNewlines(t('invalid_invite.content')),
        },
      },
      actionButton: {
        text: {
          value: t('invalid_invite.sign_in'),
        },
        onClick: handleBackToSignInButton,
      },
    },
    confirmationForgotPasswordBlock: {
      logoWithHeader: {
        text: {
          value: t('confirm_forgot_password_block.title'),
        },
        lowerText: {
          value: t('confirm_forgot_password_block.description'),
        },
      },
      actionButton: {
        text: {
          value: t('confirm_forgot_password_block.button_label'),
        },
        onClick: handleBackToSignInButton,
      },
    },
    confirmationUpdatePasswordBlock: {
      logoWithHeader: {
        text: {
          value: t('confirm_reset_password_block.title'),
        },
        lowerText: {
          value: t('confirm_reset_password_block.description'),
        },
      },
      actionButton: {
        text: {
          value: t('confirm_reset_password_block.button_label'),
        },
        onClick: handleBackToSignInButton,
      },
    },
  };
  return props;
};

export default usePresenter;
