import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmptyString } from '../../../lib/utils';
import { setForgotPasswordUseCase } from './ForgotPasswordBlock.interactor';
import { ForgotPasswordBlockCombinedProps } from './types';

const usePresenter = (props: ForgotPasswordBlockCombinedProps): ForgotPasswordBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const formInvalid = (isEmptyString(email));

  const handleEmail = (event: string) => {
    setEmail(event);
  };

  const handleBackButton = () => {
    history.push('/');
  };

  const handleForgotPassword = async () => {
    if (email) {
      try {
        await setForgotPasswordUseCase({ email });
        history.push(AUTH_ROUTES.forgotPasswordConfirmed);
      } catch (error) {
        console.log(error);
      }
    }
  };

  props = {
    ...props,
    button: {
      onClick: handleBackButton,
    },
    logoWithHeader: {
      text: {
        value: t('forgot_password_block.title'),
      },
      lowerText: {
        value: t('forgot_password_block.description'),
      },
    },
    emailField: {
      label: {
        value: t('forgot_password_block.text_field_label'),
      },
      textInput: {
        textPlaceholder: t('forgot_password_block.placeholder'),
        onTextChanged: handleEmail,
        dataTestId: 'email-field',
      },
    },
    signUpButton: {
      text: {
        value: t('forgot_password_block.button_label'),
      },
      onClick: handleForgotPassword,
      disabled: formInvalid,
      dataTestId: 'submit-button',
    },
  };

  return props;
};

export default usePresenter;
