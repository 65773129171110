import {
  BorderProps,
  ButtonProps,
  TextProps,
} from 'spindl-shared-components';
import { TeamCardProps } from '../TeamCard';


type DeleteTeammateModalInternalProps = {
  title?: TextProps;
  closeButton?: ButtonProps;
  border?: BorderProps;
  message?: TextProps;
  teamCard?: TeamCardProps;
  deleteButton?: ButtonProps;
  cancelButton?: ButtonProps;
};

type DeleteTeammateModalPropsValues = {
  '': DeleteTeammateModalInternalProps;
};

const propValues: DeleteTeammateModalPropsValues = {
  '': {
    title: {
      align: 'Left',
      styleType: 'SemiBold',
      colour: 'Dark',
      type: 'Heading5',
    },
    closeButton: {
      type: 'IconNoBG',
      size: 'S',
      styleType: 'Primary',
      state: 'Default',
    },
    border: {
      colour: 'SubduedLight',
      type: 'Horizontal',
      styleType: 'Default',
    },
    message: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Dark',
      type: 'Body1',
    },
    teamCard: {
      state: 'Added',
      type: 'NoButton',
    },
    deleteButton: {
      type: 'Text',
      size: 'M',
      styleType: 'Destructive',
      state: 'Default',
    },
    cancelButton: {
      type: 'Text',
      size: 'M',
      styleType: 'PrimarySubdued',
      state: 'Default',
    },
  },
};

const getProps = (type: string): DeleteTeammateModalInternalProps => {
  const values: DeleteTeammateModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
