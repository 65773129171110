export const AUTH_ROUTES = {
  signUp: '/auth/signUp',
  signIn: '/auth/signIn',
  forgotPassword: '/auth/forgotPassword',
  forgotPasswordConfirmed: '/auth/forgotPassword/confirmed',
  updatePassword: '/auth/updatePassword',
  updatedPasswordConfirmed: '/auth/updatePassword/confirmed',
  invalidInvite: '/auth/invalidInvite',
};

export const BASE_SERVICE_ROUTES = {
  users: '/apps/:id/users',
  communication: '/apps/:id/communication',
  data: '/apps/:id/data',
  pay: '/apps/:id/pay',
  team: '/apps/:id/team',
};

export const EDITOR_ROUTES = {
  communication: '/apps/:appId/communication/editor/:templateId',
  data: '/apps/:appId/data/database/:databaseSlug/(table)?/:tableSlug?',
};

export const SERVICES_ORDER = {
  'identity': 1,
  'communication': 2,
  'data': 3,
  'pay': 4,
};

export const NOTIFICATION_DURATION = 3000;
