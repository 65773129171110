import React, { useContext } from 'react';
import cx from 'classnames';
import { LoadingSpinner } from 'spindl-shared-components';

import TeamCardList from '../../organisms/TeamCardList';
import styles from './TeamsBlock.module.scss';
import usePresenter from './TeamsBlock.presenter';
import getProps from './TeamsBlock.props';
import { TeamsBlockCombinedProps } from './types';
import { MembersContext } from '../../../modules/member/MembersContext';


const TeamsBlock: React.FC<TeamsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    teamCardList,
  } = usePresenter(props);

  const { isTeamLoading } = useContext(MembersContext);

  const internalProps = getProps('');
  const currentStyle = styles.teamsBlock;

  if (isTeamLoading) {
    return (
      <div className={cx(styles.teamsBlockLoading)}>
        <LoadingSpinner
          {...internalProps.loadingSpinner}
          isActive
          classes={{
            spinner: cx(styles.spinner),
            path: cx(styles.path),
          }} 
        />
      </div>
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      <TeamCardList
        className={cx(styles.teamCardList, classes?.teamCardList)}
        {...internalProps.teamCardList}
        {...teamCardList}
      />
    </div>
  );
};

export default TeamsBlock;
