import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useParams, useHistory, withRouter } from 'react-router-dom';

import { AUTH_ROUTES } from '../../lib/constants';
import { UserContext } from '../user';
import { AppsContext } from './AppsContext';
import { acceptInvite } from './apps.repository';

const HandleApps = (props) => {
  const history = useHistory();
  const { appId } = useParams<{ appId: string }>();
  const { apps } = useContext(AppsContext);
  const { user } = useContext(UserContext);

  const appIds = apps?.map((app) => app.id);
  const isMember = appIds?.includes(Number(appId));

  const acceptInviteMutation = useMutation(() => {
    return acceptInvite(Number(appId), Number(user?.id));
  });

  if (!apps?.length || acceptInviteMutation.isLoading) {
    return null;
  }

  if (acceptInviteMutation.isSuccess) {
    history.go(0);
  }

  if (!isMember) {
    if (acceptInviteMutation.isError) {
      history.push(AUTH_ROUTES.invalidInvite);
    } else {
      if (appId && user?.id) {
        acceptInviteMutation.mutate();
      }
    }
  }

  return props.children;
};

export default withRouter(HandleApps);
