import {
  ButtonProps,
  TextFieldProps,
  PairTextFieldsProps,
  TextProps,
} from 'spindl-shared-components';
import { LogoWithHeaderProps } from '../../molecules/LogoWithHeader';


type SignUpBlockInternalProps = {
  logoWithHeader?: LogoWithHeaderProps;
  nameField?: PairTextFieldsProps;
  emailField?: TextFieldProps;
  passwordField?: TextFieldProps;
  confirmPasswordField?: TextFieldProps;
  signUpButton?: ButtonProps;
  text?: TextProps;
  button?: ButtonProps;
};

type SignUpBlockPropsValues = {
  '': SignUpBlockInternalProps;
};

const propValues: SignUpBlockPropsValues = {
  '': {
    logoWithHeader: {
      type: 'Title',
    },
    nameField: {
    },
    emailField: {
      state: 'Default',
      type: 'Text',
      error: 'None',
    },
    passwordField: {
      state: 'Default',
      type: 'Password',
      error: 'None',
    },
    confirmPasswordField: {
      state: 'Default',
      type: 'Password',
      error: 'None',
    },
    signUpButton: {
      type: 'Text',
      size: 'L',
      styleType: 'Primary',
      state: 'Default',
    },
    text: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Dark',
      type: 'Body1',
    },
    button: {
      type: 'TextNoBG',
      size: 'S',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): SignUpBlockInternalProps => {
  const values: SignUpBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
