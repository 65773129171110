import {
  AccountMenuDropDownProps,
  BorderProps,
  SpindlLogoProps,
} from 'spindl-shared-components';


type TopNavInternalProps = {
  spindlLogo?: SpindlLogoProps;
  accountMenuDropDown?: AccountMenuDropDownProps;
  border?: BorderProps;
};

type TopNavPropsValues = {
  '': TopNavInternalProps;
};

const propValues: TopNavPropsValues = {
  '': {
    spindlLogo: {
    },
    accountMenuDropDown: {
      state: 'Collapsed',
    },
    border: {
      colour: 'Subdued',
      type: 'Horizontal',
      styleType: 'Default',
    },
  },
};

const getProps = (type: string): TopNavInternalProps => {
  const values: TopNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
