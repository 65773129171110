import cx from 'classnames';
import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AppsContext } from '../../../modules/apps/AppsContext';
import styles from './LeftNavOverride.module.scss';
import { LeftNavCombinedProps } from './types';
import { getCurrentAppServicesUseCase } from './LeftNav.interactor';

const usePresenter = (props: LeftNavCombinedProps): LeftNavCombinedProps => {
  const { t } = useTranslation();
  const { appId, activePage } = useParams<{ appId: string, activePage: string }>();
  const { apps, activeApp, updateActiveApp } = useContext(AppsContext);
  const { data: services } = useQuery(['getServices', appId], () => getCurrentAppServicesUseCase(Number(appId)));
  const currService = activePage === 'users' ? 'identity' : activePage;
  const history = useHistory();

  const changeActiveApp = (id?: number) => {
    if (id !== activeApp?.id) {
      history.push(`/apps/${id}/users`);
    }
  };

  const changeActivePage = (page: string) => {
    if (page === 'identity') {
      history.push(`/apps/${activeApp?.id}/users`);
    } else {
      history.push(`/apps/${activeApp?.id}/${page}`);
    }
  };

  //TODO: add support for the rest of the services when they are ready
  const getServiceIcon = (service: string) => {
    switch (service) {
      case 'identity':
        return 'UsersOutline';
      case 'communication':
        return 'EmailOutline';
      case 'data':
        return 'DataOutline';
    }
  };

  props = {
    ...props,
    className: cx(props.className, styles.appDropDown),
  };

  useEffect(() => {
    updateActiveApp(apps?.find(app => app.id === Number(appId)));
  }, [apps, appId, updateActiveApp, activePage, currService]);

  props = {
    ...props,
    appDropDown: {
      menuProps: {
        appDropDownItems: apps?.map((app, index) => {
          return {
            appName: {
              value: app.name,
              dataTestId: `app-name${index}-text`,
            },
            appImage: {
              imageSrc: app.iconUrl,
            },
            onClick: () => changeActiveApp(app.id),
            state: app === activeApp ? 'Selected' : 'Unselected',
          };
        }),
      },
      toggleProps: {
        appName: {
          value: activeApp?.name,
          dataTestId: 'active-app-name-text',
        },
        appImage: {
          imageSrc: activeApp?.iconUrl,
        },
      },
    },
    serviceList: {
      verticalTabItems: services?.map((service, index) => {
        return {
          text: {
            value: t(`left_nav.${service.serviceType.toLowerCase()}`),
            dataTestId: `service-type${index}-text`,
          },
          icon: {
            asset: getServiceIcon(service.serviceType.toLowerCase()),
            colour: 'IconSubdued',
          },
          state: service.serviceType.toLowerCase() === currService ? 'Selected' : 'Unselected',
          onClick: () => changeActivePage(service.serviceType.toLowerCase()),
        };
      }),
    },
    text: {
      value: t('left_nav.app_settings'),
      dataTestId: 'app-settings-text',
    },
    settingsList: {
      verticalTabItems: [
        {
          text: {
            value: t('left_nav.team'),
            dataTestId: 'team-text',
          },
          icon: {
            asset: activePage === 'team' ? 'TeamFilled' : 'TeamHollow',
          },
          state: activePage === 'team' ? 'Selected' : 'Unselected',
          onClick: () => changeActivePage('team'),
        },
      ],
    },
  };

  return props;
};

export default usePresenter;
