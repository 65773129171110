import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';

import { NotificationContainer } from 'spindl-shared-components';
import { CommunicationModule } from 'spindl-communication-mod';
import { DataModule } from 'spindl-data-mod';
import { UserModule } from 'spindl-user-mod';

import { BASE_SERVICE_ROUTES } from '../../../lib/constants';
import LeftNav from '../../organisms/LeftNav';
import TopNav from '../../organisms/TopNav';
import TeamsPage from '../../pages/TeamsPage';
import styles from './MainLayout.module.scss';
import usePresenter from './MainLayout.presenter';
import getProps from './MainLayout.props';
import { MainLayoutCombinedProps } from './types';

const MainLayout: React.FC<MainLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
    leftNav,
    teamsPage,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.mainLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <TopNav
        className={cx(styles.topNav, classes?.topNav)}
        {...internalProps.topNav}
        {...topNav}
      />
      <div className={cx(styles.content, classes?.content)}>
        <LeftNav
          className={cx(styles.leftNav, classes?.leftNav)}
          {...internalProps.leftNav}
          {...leftNav}
        />

        {/* Notification container used accross all modules */}
        <NotificationContainer className={styles.notificationContainer} />

        <div style={{ width: '100%', height: '100%' }}>
          <Switch>
            {/* Team page */}
            <Route path={BASE_SERVICE_ROUTES.team}>
              <TeamsPage
                className={cx(styles.teamsPage, classes?.teamsPage)}
                {...internalProps.teamsPage}
                {...teamsPage}
              />
            </Route>

            {/* Spindl services */}
            <Route path={BASE_SERVICE_ROUTES.users} component={UserModule} />
            <Route path={BASE_SERVICE_ROUTES.communication} component={CommunicationModule} />
            <Route path={BASE_SERVICE_ROUTES.data} component={DataModule} />
            <Route path={BASE_SERVICE_ROUTES.pay} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
