import React from 'react';
import cx from 'classnames';

import InformationBlock from '../../blocks/InformationBlock';

import styles from './ErrorPage.module.scss';
import usePresenter from './ErrorPage.presenter';
import getProps from './ErrorPage.props';
import { ErrorPageCombinedProps } from './types';


const ErrorPage: React.FC<ErrorPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    informationBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.errorPage;

  return (
    <div className={cx(currentStyle, className)}>
      <InformationBlock
        className={cx(styles.informationBlock, classes?.informationBlock)}
        {...internalProps.informationBlock}
        {...informationBlock}
      />
    </div>
  );
};

export default ErrorPage;

