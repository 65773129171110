import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { AUTH_ROUTES } from '../../../lib/constants';
import { extractJwtPayload } from '../../../lib/tokenManager';
import { isEmptyString } from '../../../lib/utils';
import { ResetPasswordBlockCombinedProps } from './types';
import { setUpdatePasswordUseCase } from './ResetPasswordBlock.interactor';

type ResetPasswordState = {
  createPassword: string;
  confirmPassword: string;
};

const ResetPasswordInitialState = {
  createPassword: '',
  confirmPassword: '',
};

const usePresenter = (props: ResetPasswordBlockCombinedProps): ResetPasswordBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const [createPasswordShown, setCreatePasswordShown] = useState<boolean>(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);
  const [resetPasswordState, setResetPasswordState] = useState<ResetPasswordState>(ResetPasswordInitialState);
  const [resetPasswordErrorState, setResetPasswordErrorState] = useState<ResetPasswordState>(ResetPasswordInitialState);

  const handleTextChange = (field: keyof ResetPasswordState) => {
    return (value: string) => {
      setResetPasswordState({
        ...resetPasswordState,
        [field]: value,
      });
    };
  };

  const validForm = () => {
    const invalidFormErrors = {
      createPassword: '',
      confirmPassword: '',
    };

    if (isEmptyString(resetPasswordState.createPassword) || isEmptyString(resetPasswordState.confirmPassword)) {
      if (isEmptyString(resetPasswordState.createPassword)) {
        invalidFormErrors.createPassword = t('reset_password_block.empty_password');
      }
      if (isEmptyString(resetPasswordState.confirmPassword)) {
        invalidFormErrors.confirmPassword = t('reset_password_block.empty_password');
      }
      setResetPasswordErrorState(invalidFormErrors);
      return false;
    }

    if (resetPasswordState.createPassword !== resetPasswordState.confirmPassword) {
      invalidFormErrors.createPassword = t('reset_password_block.different_password');
      invalidFormErrors.confirmPassword = t('reset_password_block.different_password');
      setResetPasswordErrorState(invalidFormErrors);
      return false;
    }

    if (resetPasswordState.createPassword.length < 8) {
      invalidFormErrors.createPassword = t('reset_password_block.weak_password');
      invalidFormErrors.confirmPassword = t('reset_password_block.weak_password');
      setResetPasswordErrorState(invalidFormErrors);
      return false;
    }

    return true;
  };

  const handleUpdatePassword = async () => {
    if (!validForm()) {
      return;
    }

    if (token) {
      const { id } = extractJwtPayload(token);
      try {
        await setUpdatePasswordUseCase({
          id,
          password: resetPasswordState.createPassword,
          token,
        });
        history.push(AUTH_ROUTES.updatedPasswordConfirmed);
      } catch (error) {
        console.log(error);
      }
    }
  };

  props = {
    ...props,
    logoWithHeader: {
      text: {
        value: t('reset_password_block.title'),
      },
      lowerText: {
        value: t('reset_password_block.description'),
      },
    },
    newPasswordField: {
      label: {
        value: t('reset_password_block.create_new_password'),
      },
      textInput: {
        htmlType: createPasswordShown ? 'text' : 'password',
        button: {
          dataTestId: 'create-password-visibility',
          onClick: () => setCreatePasswordShown(!createPasswordShown),
          styleType: 'Primary',
          icon: {
            asset: createPasswordShown ? 'HidePassword' : 'ShowPassword',
          },
        },
        onTextChanged: handleTextChange('createPassword'),
        dataTestId: 'create-password-field',
      },
      text: {
        value: resetPasswordErrorState.createPassword,
        dataTestId: 'create-password-error',
      },
      error: resetPasswordErrorState.createPassword === '' ? 'None' : 'Error',
    },
    confirmNewPasswordField: {
      label: {
        value: t('reset_password_block.confirm_new_password'),
      },
      textInput: {
        htmlType: confirmPasswordShown ? 'text' : 'password',
        button: {
          dataTestId: 'confirm-password-visibility',
          onClick: () => setConfirmPasswordShown(!confirmPasswordShown),
          styleType: 'Primary',
          icon: {
            asset: confirmPasswordShown ? 'HidePassword' : 'ShowPassword',
          },
        },
        onTextChanged: handleTextChange('confirmPassword'),
        dataTestId: 'confirm-password-field',
      },
      text: {
        value: resetPasswordErrorState.confirmPassword,
        dataTestId: 'confirm-password-error',
      },
      error: resetPasswordErrorState.confirmPassword === '' ? 'None' : 'Error',
    },
    signUpButton: {
      text: {
        value: t('reset_password_block.update_password_button'),
      },
      dataTestId: 'update-password',
      onClick: handleUpdatePassword,
    },
  };

  return props;
};

export default usePresenter;
