import React from 'react';
import cx from 'classnames';

import { Button } from 'spindl-shared-components';
import LogoWithHeader from '../../molecules/LogoWithHeader';

import styles from './InformationBlock.module.scss';
import usePresenter from './InformationBlock.presenter';
import getProps from './InformationBlock.props';
import { InformationBlockCombinedProps } from './types';


const InformationBlock: React.FC<InformationBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    logoWithHeader,
    actionButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.informationBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <LogoWithHeader
        className={cx(styles.logoWithHeader, classes?.logoWithHeader)}
        {...internalProps.logoWithHeader}
        {...logoWithHeader}
      />
      <Button
        className={cx(styles.actionButton, classes?.actionButton)}
        {...internalProps.actionButton}
        {...actionButton}
      />
    </div>
  );
};

export default InformationBlock;
