import React from 'react';
import cx from 'classnames';

import TeamsBlock from '../../blocks/TeamsBlock';
import SubNav from '../../organisms/SubNav';
import DeleteTeammateModal from '../../organisms/DeleteTeammateModal';

import styles from './TeamsPage.module.scss';
import usePresenter from './TeamsPage.presenter';
import getProps from './TeamsPage.props';
import { TeamsPageCombinedProps } from './types';
import TeammateInviteModal from '../../organisms/TeammateInviteModal';
import DeleteInviteModal from '../../organisms/DeleteInviteModal';


const TeamsPage: React.FC<TeamsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    subNav,
    teamsBlock,
    modalState,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teamsPage;

  return (
    <div
      className={cx(currentStyle, className)}
      id="teams-page"
    >
      <TeammateInviteModal
        className={styles.modalWrapper}
        show={modalState === 'INVITE_MODAL'}
      />
      <DeleteTeammateModal 
        className={styles.modalWrapper}
        show={modalState === 'DELETE_MODAL'}
      />
      <DeleteInviteModal
        className={styles.modalWrapper}
        show={modalState === 'DELETE_INVITE_MODAL'}
      />
      <SubNav
        className={cx(styles.subNav, classes?.subNav)}
        {...internalProps.subNav}
        {...subNav}
      />
      <div className={cx(styles.pageContainer, classes?.pageContainer)}>
        <TeamsBlock
          className={cx(styles.teamsBlock, classes?.teamsBlock)}
          {...internalProps.teamsBlock}
          {...teamsBlock}
        />
      </div>
    </div>
  );
};

export default TeamsPage;
