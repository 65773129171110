import React from 'react';
import cx from 'classnames';

import { Button, TextField } from 'spindl-shared-components';
import LogoWithHeader from '../../molecules/LogoWithHeader';

import styles from './ForgotPasswordBlock.module.scss';
import usePresenter from './ForgotPasswordBlock.presenter';
import getProps from './ForgotPasswordBlock.props';
import { ForgotPasswordBlockCombinedProps } from './types';


const ForgotPasswordBlock: React.FC<ForgotPasswordBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    logoWithHeader,
    emailField,
    signUpButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.forgotPasswordBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <div className={cx(styles.headerContent, classes?.headerContent)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          <LogoWithHeader
            className={cx(styles.logoWithHeader, classes?.logoWithHeader)}
            {...internalProps.logoWithHeader}
            {...logoWithHeader}
          />
        </div>
        <TextField
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}
        />
      </div>
      <Button
        className={cx(styles.signUpButton, classes?.signUpButton)}
        {...internalProps.signUpButton}
        {...signUpButton}
      />
    </div>
  );
};

export default ForgotPasswordBlock;
