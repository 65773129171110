import {
  TextFieldProps,
  ButtonProps,
} from 'spindl-shared-components';
import { LogoWithHeaderProps } from '../../molecules/LogoWithHeader';


type ResetPasswordBlockInternalProps = {
  logoWithHeader?: LogoWithHeaderProps;
  newPasswordField?: TextFieldProps;
  confirmNewPasswordField?: TextFieldProps;
  signUpButton?: ButtonProps;
};

type ResetPasswordBlockPropsValues = {
  '': ResetPasswordBlockInternalProps;
};

const propValues: ResetPasswordBlockPropsValues = {
  '': {
    logoWithHeader: {
      type: 'TitleWithDescription',
    },
    newPasswordField: {
      state: 'Default',
      type: 'Password',
      error: 'None',
    },
    confirmNewPasswordField: {
      state: 'Default',
      type: 'Password',
      error: 'None',
    },
    signUpButton: {
      type: 'Text',
      size: 'L',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): ResetPasswordBlockInternalProps => {
  const values: ResetPasswordBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
