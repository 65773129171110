import React from 'react';
import cx from 'classnames';

import {
  Border,
  HorizontalTabList,
} from 'spindl-shared-components';

import styles from './SubNav.module.scss';
import usePresenter from './SubNav.presenter';
import getProps from './SubNav.props';
import { SubNavCombinedProps } from './types';


const SubNav: React.FC<SubNavCombinedProps> = (props) => {
  const {
    className,
    classes,
    horizontalTabList,
    border,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.subNav;

  return (
    <div className={cx(currentStyle, className)}>
      <HorizontalTabList
        className={cx(styles.horizontalTabList, classes?.horizontalTabList)}
        {...internalProps.horizontalTabList}
        {...horizontalTabList}
      />
      <Border
        className={cx(styles.border, classes?.border)}
        {...internalProps.border}
        {...border}
      />
    </div>
  );
};

export default SubNav;
