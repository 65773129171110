import React from 'react';
import cx from 'classnames';

import {
  Text,
} from 'spindl-shared-components';

import styles from './TitleWithDescription.module.scss';
import usePresenter from './TitleWithDescription.presenter';
import getProps from './TitleWithDescription.props';
import { TitleWithDescriptionCombinedProps } from './types';


const TitleWithDescription: React.FC<TitleWithDescriptionCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    subtitle,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.titleWithDescription;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.title, classes?.title)}
        {...internalProps.title}
        {...title}
      />
      <Text
        className={cx(styles.subtitle, classes?.subtitle)}
        {...internalProps.subtitle}
        {...subtitle}
      />
    </div>
  );
};

export default TitleWithDescription;
