import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useNotification } from 'spindl-shared-components';

import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { getInitials } from '../../../lib/utils';
import { MembersContext } from '../../../modules/member/MembersContext';
import { AppInvite, MemberInfo } from '../../../modules/member/types';
import { TeamCardCombinedProps } from '../../organisms/TeamCard/types';
import { TeamsBlockCombinedProps } from './types';

const usePresenter = (props: TeamsBlockCombinedProps): TeamsBlockCombinedProps => {
  const { t } = useTranslation();
  const { trigger } = useNotification();

  const { account } = useAuth();

  const { members, openInvites, updateModalState, updateSelectedMember, updateSelectedInvite, appInviteMutation } = useContext(MembersContext);
  
  const setSelectedMember = (member: MemberInfo) => {
    updateSelectedMember(member);
    updateModalState('DELETE_MODAL');
  };

  const setSelectedInvite = (invite: AppInvite) => {
    if (!updateSelectedInvite) {
      return;
    }

    updateSelectedInvite(invite);
    updateModalState('DELETE_INVITE_MODAL');
  };

  if (!members) {
    return props;
  }

  const resendEmailInvite = async (invite: AppInvite) => {
    if (account && appInviteMutation) {
      await appInviteMutation(
        {
          email: invite.email,
          senderFirstName: account.firstName,
          senderLastName: account.lastName,
          senderEmail: account.email,
        },
      );

      trigger({
        message: getTranslation('toast_notification.invite_message', {
          email: `${invite.email}`,
        }),
        type: 'Positive',
        duration: NOTIFICATION_DURATION,
      });
    }
  };

  const teamCards: TeamCardCombinedProps[] = members.map((member) => {
    return {
      initials: {
        text: {
          value: getInitials(member.firstName, member.lastName),
          dataTestId: 'initials-text',
        },
      },
      titleWithDescription: {
        title: {
          value: `${member.firstName} ${member.lastName}`,
          dataTestId: 'name-text',
        },
        subtitle: {
          value: member.email,
          dataTestId: 'email-text',
        },
      },
      role: {
        value: 'Admin', // FIX ME: Hard-coded for now based on the acceptance criteria
        dataTestId: 'role-text',
      },
      iconDropDown: {
        toggleProps: {
          type: 'PillButton',
          button: {
            icon: {
              asset: 'CardMenu',
            },
          },
        },
        menuProps: {
          dropDownItems: [
            {
              type: 'DefaultIconText',
              text: {
                value: t('delete_teammate_modal.delete_user'),
              },
              icon: {
                asset: 'Delete',
                colour: 'IconDark',
              },
              onClick: () => setSelectedMember(member),
            },
          ],
        },
      },
    };
  });

  if (openInvites) {
    const openInviteCards: TeamCardCombinedProps[] = openInvites?.map((openInvite) => {
      return {
        state: 'Pending',
        type: 'Button',
        icon: {
          asset: 'Clock',
        },
        email: {
          value: openInvite.email,
        },
        status: {
          value: t('team_card.pending'),
        },
        iconDropDown: {
          toggleProps: {
            type: 'PillButton',
            button: {
              icon: {
                asset: 'CardMenu',
              },
            },
          },
          menuProps: {
            dropDownItems: [
              {
                type: 'DefaultIconText',
                text: {
                  value: t('resend_invite.resend_invite'),
                },
                icon: {
                  asset: 'EmailOutline',
                  colour: 'IconSubdued',
                },
                onClick: () => resendEmailInvite(openInvite),
              },
              {
                type: 'DefaultIconText',
                text: {
                  value: t('delete_invite_modal.delete_invite'),
                },
                icon: {
                  asset: 'Delete',
                  colour: 'IconDark',
                },
                onClick: () => setSelectedInvite(openInvite),
              },
            ],
          },
        },
      };
    });

    teamCards.unshift(...openInviteCards);
  }

  const addTeammateCard: TeamCardCombinedProps = {
    state: 'AddNew',
    onClick: () => {},
    button: {
      text: {
        value: t('teams_block.add_teammate'),
      },
      dataTestId: 'add-a-teammate-button',
    },
  };

  teamCards.unshift(addTeammateCard);

  props = {
    ...props,
    teamCardList: {
      teamCards: teamCards,
    },
  };
  return props;
};

export default usePresenter;
