import {
  ButtonProps,
} from 'spindl-shared-components';
import { LogoWithHeaderProps } from '../../molecules/LogoWithHeader';


type InformationBlockInternalProps = {
  logoWithHeader?: LogoWithHeaderProps;
  actionButton?: ButtonProps;
};

type InformationBlockPropsValues = {
  '': InformationBlockInternalProps;
};

const propValues: InformationBlockPropsValues = {
  '': {
    logoWithHeader: {
      type: 'TitleWithDescription',
    },
    actionButton: {
      type: 'Text',
      size: 'L',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): InformationBlockInternalProps => {
  const values: InformationBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
