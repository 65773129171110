import { TeamsBlockProps } from '../../blocks/TeamsBlock';
import { DeleteTeammateModalProps } from '../../organisms/DeleteTeammateModal';
import { SubNavProps } from '../../organisms/SubNav';


type TeamsPageInternalProps = {
  subNav?: SubNavProps;
  teamsBlock?: TeamsBlockProps;
  deleteTeammateModal?: DeleteTeammateModalProps;
};

type TeamsPagePropsValues = {
  '': TeamsPageInternalProps;
};

const propValues: TeamsPagePropsValues = {
  '': {
    subNav: {
    },
    teamsBlock: {
    },
    deleteTeammateModal: {
    },
  },
};

const getProps = (type: string): TeamsPageInternalProps => {
  const values: TeamsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
