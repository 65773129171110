import cx from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MembersContext } from '../../../modules/member/MembersContext';
import { TeamCardCombinedProps } from './types';
import styles from './TeamCardOverride.module.scss';

const usePresenter = (props: TeamCardCombinedProps): TeamCardCombinedProps => {
  const { updateModalState } = useContext(MembersContext);
  const { t } = useTranslation();

  props = {
    ...props,
    onClick: () => updateModalState('INVITE_MODAL'),
    button: {
      text: {
        value: t('teams_block.add_teammate'),
      },
    },
  };

  return {
    ...props,
    className: cx(
      props.className,
      props.state === 'AddNew' ? styles.teamCardAddNewButton : '',
    ),
    classes: {
      ...props.classes,
      titleWithDescription: styles.textOverflowEllipsis,
      topContent: styles.textOverflowEllipsis,
    },
  };
};

export default usePresenter;
