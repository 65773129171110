import React from 'react';
import cx from 'classnames';

import SignInPage from '../../pages/SignInPage';

import styles from './SignInLayout.module.scss';
import usePresenter from './SignInLayout.presenter';
import getProps from './SignInLayout.props';
import { SignInLayoutCombinedProps } from './types';


const SignInLayout: React.FC<SignInLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    signInPage,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signInLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <SignInPage
        className={cx(styles.signInPage, classes?.signInPage)}
        {...internalProps.signInPage}
        {...signInPage}
      />
    </div>
  );
};

export default SignInLayout;
