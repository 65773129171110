import { createContext, useContext, useEffect, useState } from 'react';
import { UseMutateAsyncFunction, useMutation, useQuery } from 'react-query';
import { useAuth } from 'spindl-shared-components';
import { AppsContext } from '../apps/AppsContext';
import { getMembersInfoByAppId, getOpenInvites, appInvite } from './member.repository';
import { AppInvite, AppInvitePayload, MemberInfo, ModalState } from './types';

export type MembersContextValue = {
  members?: MemberInfo[];
  refetchMembers?: () => void;
  selectedMember?: MemberInfo;
  updateSelectedMember: (member: MemberInfo) => void;
  openInvites?: AppInvite[];
  refetchOpenInvites?: () => void;
  modalState?: ModalState;
  updateModalState: (state: ModalState) => void;
  selectedInvite?: AppInvite;
  updateSelectedInvite?: (invite: AppInvite) => void;
  isMembersLoading?: boolean;
  appInviteMutation?: UseMutateAsyncFunction<void, unknown, AppInvitePayload, unknown>;
  isTeamLoading?: boolean;
};

export const initialMembersContext: MembersContextValue = {
  members: undefined,
  refetchMembers: () => {},
  updateSelectedMember: () => {},
  updateModalState: () => {},
  openInvites: undefined,
  refetchOpenInvites: () => {},
  modalState: 'UNSET',
  selectedMember: undefined,
  selectedInvite: undefined,
  updateSelectedInvite: () => {},
  appInviteMutation: undefined,
};

export const MembersContext = createContext<MembersContextValue>(initialMembersContext);  

export const MembersProvider: React.FC<{}> = ({ children }) => {
  const { activeApp } = useContext(AppsContext);
  const { account } = useAuth();

  const [selectedMember, setSelectedMember] = useState<MemberInfo>();
  const [openInvites, setOpenInvites] = useState<AppInvite[]>([]);
  const [modalState, setModalState] = useState<ModalState>('UNSET');

  const [selectedInvite, setSelectedInvite] = useState<AppInvite>();

  // The queries will only be enabled when activeApp or account are defined, so we can safely use
  // the non-null assertion
  const { data: members, refetch: refetchMembers, isLoading: isMembersLoading } = useQuery(['app_members', activeApp?.id, account?.id],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getMembersInfoByAppId(activeApp!.id, account!.id), { enabled: !!activeApp && !!account },
  );

  const { data: openInvitesData, isSuccess, refetch: refetchOpenInvites, isLoading: isInvitesLoading } = useQuery(['getOpenInvites', activeApp?.id],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getOpenInvites(activeApp!.id), { enabled: !!activeApp },
  );

  const { mutateAsync: appInviteMutation } = useMutation('appInvite', (payload: AppInvitePayload) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return appInvite(activeApp!.id, payload);
  });

  useEffect(() => {
    if (isSuccess && openInvitesData) {
      openInvitesData.sort((a, b) => (
        new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime() ? -1 : 1
      ));
      setOpenInvites(openInvitesData);
    }
  }, [openInvitesData, isSuccess]);

  const updateModalState = (state: ModalState) => {
    setModalState(state);
  };

  const updateSelectedMember = (member: MemberInfo) => {
    setSelectedMember(member);
  };

  const updateSelectedInvite = setSelectedInvite;

  return (
    <MembersContext.Provider value={{
      members,
      refetchMembers,
      selectedMember,
      updateSelectedMember,
      openInvites,
      refetchOpenInvites,
      modalState,
      updateModalState,
      isTeamLoading: isMembersLoading || isInvitesLoading,
      selectedInvite,
      updateSelectedInvite,
      appInviteMutation,
    }}>
      {children}
    </MembersContext.Provider>
  );
};
