import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from 'spindl-shared-components';

import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { getInitials } from '../../../lib/utils';
import { AppsContext } from '../../../modules/apps/AppsContext';
import { MembersContext } from '../../../modules/member/MembersContext';
import { deleteTeamMemberUseCase } from './DeleteTeammateModal.interactor';
import { DeleteTeammateModalCombinedProps } from './types';

const usePresenter = (props: DeleteTeammateModalCombinedProps): DeleteTeammateModalCombinedProps => {
  const { t } = useTranslation();
  const { trigger } = useNotification();

  const { activeApp } = useContext(AppsContext);
  const { updateModalState, selectedMember, refetchMembers } = useContext(MembersContext);

  if (!selectedMember) {
    return props;
  }

  const { firstName, lastName } = selectedMember;

  const handleDeleteUser = async () => {
    try {
      await deleteTeamMemberUseCase(selectedMember?.id);

      updateModalState('UNSET');
      trigger({
        message: getTranslation('toast_notification.delete_teammate_message', {
          name: `${selectedMember?.firstName} ${selectedMember?.lastName}`,
        }),
        type: 'Positive',
        duration: NOTIFICATION_DURATION,
      });

      if (refetchMembers) {
        refetchMembers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamMessage = () => {
    return getTranslation('delete_teammate_modal.message', {
      name: `${firstName} ${lastName}`,
      app: activeApp?.name,
    });
  };

  props = {
    ...props,
    title: {
      value: t('delete_teammate_modal.delete_user'),
    },
    closeButton: {
      icon: {
        asset: 'Close',
      },
      onClick: () => updateModalState('UNSET'),
    },
    message: {
      value: getTeamMessage(),
    },
    teamCard: {
      initials: {
        text: {
          value: getInitials(firstName, lastName),
        },
      },
      titleWithDescription: {
        title: {
          value: `${firstName} ${lastName}`,
        },
        subtitle: {
          value: selectedMember?.email,
        },
      },
      role: {
        value: selectedMember?.role,
      },
    },
    deleteButton: {
      text: {
        value: t('delete_teammate_modal.delete_user'),
      },
      onClick: handleDeleteUser,
    },
    cancelButton: { 
      text: {
        value: t('delete_teammate_modal.cancel_button'),
      },
      onClick: () => updateModalState('UNSET'),
    },
  };

  return props;
};

export default usePresenter;
