import { LoadingSpinnerProps } from 'spindl-shared-components';
import { TeamCardListProps } from '../../organisms/TeamCardList';


type TeamsBlockInternalProps = {
  teamCardList?: TeamCardListProps;
  loadingSpinner?: LoadingSpinnerProps;
};

type TeamsBlockPropsValues = {
  '': TeamsBlockInternalProps;
};

const propValues: TeamsBlockPropsValues = {
  '': {
    teamCardList: {},
    loadingSpinner: {
      size: 'Large',
      styleType: 'Primary',
    },
  },
};

const getProps = (type: string): TeamsBlockInternalProps => {
  const values: TeamsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
