import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import useUpdateAccount from '../../../modules/auth/useUpdateAccount';
import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { SignInPayload } from '../../../modules/auth/types';
import { getLoginTokenUseCase } from './SignInBlock.interactor';
import { SignInBlockCombinedProps, TextFieldErrorEnum } from './types';

const usePresenter = (props: SignInBlockCombinedProps): SignInBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateAccount } = useUpdateAccount();

  // Get the return_to search parameter
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const returnTo = query.get('return_to');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailErrorState, setEmailErrorState] = useState<TextFieldErrorEnum>('None');
  const [passwordErrorState, setPasswordErrorState] = useState<TextFieldErrorEnum>('None');
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const formInvalid = (isEmptyString(email) || isEmptyString(password));

  const handleEmail = (event: string) => {
    setEmail(event);
  };
  const handlePassword = (event: string) => {
    setPassword(event);
  };

  const handleForgotPassword = () => {
    history.push(AUTH_ROUTES.forgotPassword);
  };

  const signInMutation = useMutation((payload: SignInPayload) => {
    return getLoginTokenUseCase(payload);
  });

  const resetErrorState = () => {
    setEmailErrorState('None');
    setPasswordErrorState('None');
    setEmailErrorText('');
    setPasswordErrorText('');
  };

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordVisibility(false);

    resetErrorState();

    if (!formInvalid && isEmail(email)) {
      try {
        const res = await signInMutation.mutateAsync({ email: email, password: password });
        updateAccount({ ...res });
        history.push(returnTo || '/'); // Route to return_to if defined
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            const errorString = t('sign_in_block.incorrect_email');
            setEmailErrorState('Error');
            setEmailErrorText(errorString);
          } else if (error.response?.status === 401) {
            const errorString = t('sign_in_block.incorrect_password');
            setPasswordErrorState('Error');
            setPasswordErrorText(errorString);
          }
        }
      }
    } else {
      if (isEmptyString(password)) {
        const errorString = t('sign_in_block.missing_password');
        setPasswordErrorState('Error');
        setPasswordErrorText(errorString);
      } if (isEmptyString(email)) {
        const errorString = t('sign_in_block.missing_email');
        setEmailErrorState('Error');
        setEmailErrorText(errorString);
      } else if (!isEmail(email)) {
        const errorString = t('sign_in_block.incorrect_email');
        setEmailErrorState('Error');
        setEmailErrorText(errorString);
      }
    }
  };

  props = {
    ...props,
    logoWithHeader: {
      text: {
        value: t('sign_in_block.title'),
      },
    },
    emailField: {
      label: {
        value: t('sign_in_block.email'),
      },
      textInput: {
        dataTestId: 'email-field',
        textValue: email,
        onTextChanged: handleEmail,
      },
      text: {
        dataTestId: 'email-error',
        value: emailErrorText,
      },
      error: emailErrorState,
    },

    passwordField: {
      label: {
        value: t('sign_in_block.password'),
      },
      textInput: {
        htmlType: passwordVisibility ? 'text' : 'password',
        button: {
          onClick: () => setPasswordVisibility(!passwordVisibility),
          styleType: 'Primary',
          icon: {
            asset: passwordVisibility ? 'HidePassword' : 'ShowPassword',
          },
        },
        dataTestId: 'password-field',
        textValue: password,
        onTextChanged: handlePassword,
      },
      text: {
        dataTestId: 'password-error',
        value: passwordErrorText,
      },
      error: passwordErrorState,
    },
    signInButton: {
      dataTestId: 'sign-in-button',
      text: {
        value: t('sign_in_block.sign_in_button'),
      },
      buttonType: 'submit',
    },
    forgotPasswordButton: {
      text: {
        value: t('sign_in_block.forgot_password_button'),
      },
      onClick: handleForgotPassword,
      buttonType: 'button',
    },
    signInForm: {
      onSubmit: signIn,
    },
  };

  return props;
};

export default usePresenter;
