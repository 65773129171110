import React from 'react';
import cx from 'classnames';

import TeamCard from '../TeamCard';

import styles from './TeamCardList.module.scss';
import usePresenter from './TeamCardList.presenter';
import getProps from './TeamCardList.props';
import { TeamCardListCombinedProps } from './types';


const TeamCardList: React.FC<TeamCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    teamCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teamCardList;

  const teamCardViews = teamCards?.map((teamCard, index) => (
    <TeamCard
      key={index}
      className={cx(styles.teamCard, classes?.teamCard)}
      {...internalProps.teamCard}
      {...teamCard}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {teamCardViews}
    </div>
  );
};

export default TeamCardList;
