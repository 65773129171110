import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth, useNotification } from 'spindl-shared-components';

import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { AppsContext } from '../../../modules/apps/AppsContext';
import { MembersContext } from '../../../modules/member/MembersContext';
import { TextFieldErrorEnum } from '../../blocks/SignInBlock/types';
import { TeammateInviteModalCombinedProps } from './types';

const usePresenter = (props: TeammateInviteModalCombinedProps): TeammateInviteModalCombinedProps => {
  const { t } = useTranslation();
  const { trigger } = useNotification();

  const { account } = useAuth();
  const { activeApp } = useContext(AppsContext);
  const { updateModalState, refetchOpenInvites, appInviteMutation } = useContext(MembersContext);

  const [email, setEmail] = useState<string>('');
  const [emailErrorState, setEmailErrorState] = useState<TextFieldErrorEnum>('None');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setEmailErrorState('None');
  }, [email]);

  const sendInviteEmail = async () => {
    setEmailErrorState('None');
    if (isEmail(email) && activeApp && account && appInviteMutation) {
      try {
        await appInviteMutation(
          {
            email,
            senderFirstName: account.firstName,
            senderLastName: account.lastName,
            senderEmail: account.email,
          },
        );

        updateModalState('UNSET');
        trigger({
          message: getTranslation('toast_notification.invite_message', {
            email: `${email}`,
          }),
          type: 'Positive',
          duration: NOTIFICATION_DURATION,
        });

        if (refetchOpenInvites) {
          refetchOpenInvites();
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 409) {
            setEmailErrorState('Error');
            setErrorMessage(t('teams_block.redundant_invite'));
          }
        }
      }
    } else if (isEmptyString(email)) {
      setEmailErrorState('Error');
      setErrorMessage(t('teams_block.no_email'));
    } else {
      setEmailErrorState('Error');
      setErrorMessage(t('teams_block.invalid_email'));
    }
  };

  props = {
    ...props,
    title: {
      value: t('teams_block.invite_teammate'),
    },
    emailField: {
      label: {
        value: t('teams_block.invite_modal_email'),
      },
      textInput: {
        dataTestId: 'email-field',
        textValue: email,
        onTextChanged: setEmail,
      },
      text: {
        value: errorMessage,
      },
      error: emailErrorState,
    },
    addButton: {
      onClick: sendInviteEmail,
      text: {
        value: t('teams_block.email_button'),
      },
      icon: {
        asset: 'Plus',
      },
      disabled: emailErrorState === 'Error' || isEmptyString(email),
    },
    closeButton: {
      onClick: () => updateModalState('UNSET'),
      icon: {
        asset: 'Close',
      },
    },
  };

  return props;
};

export default usePresenter;
