import {
  ButtonProps,
  TextProps,
  IconProps,
  IconDropDownProps,
  InitialsProps,
} from 'spindl-shared-components';
import { TitleWithDescriptionProps } from '../../molecules/TitleWithDescription';


type TeamCardInternalProps = {
  icon?: IconProps;
  button?: ButtonProps;
  initials?: InitialsProps;
  iconDropDown?: IconDropDownProps;
  titleWithDescription?: TitleWithDescriptionProps;
  role?: TextProps;
  email?: TextProps;
  status?: TextProps;
};

type TeamCardPropsValues = {
  'AddNewButton': TeamCardInternalProps;
  'AddedButton': TeamCardInternalProps;
  'AddedNoButton': TeamCardInternalProps;
  'PendingButton': TeamCardInternalProps;
  'PendingNoButton': TeamCardInternalProps;
};

const propValues: TeamCardPropsValues = {
  'AddNewButton': {
    icon: {
      asset: 'Add',
      colour: 'IconPrimary',
    },
    button: {
      type: 'Text',
      size: 'S',
      styleType: 'PrimarySubdued',
      state: 'Default',
    },
  },
  'AddedButton': {
    initials: {
      type: 'Large',
    },
    iconDropDown: {},
    titleWithDescription: {},
    role: {
      align: 'Center',
      styleType: 'Medium',
      colour: 'Dark',
      type: 'Body4',
    },
  },
  'AddedNoButton': {
    initials: {
      type: 'Large',
    },
    titleWithDescription: {},
    role: {
      align: 'Center',
      styleType: 'Medium',
      colour: 'Dark',
      type: 'Body4',
    },
  },
  'PendingButton': {
    icon: {
      asset: 'Clock',
      colour: 'IconDark',
    },
    iconDropDown: {},
    email: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Dark',
      type: 'Body3',
    },
    status: {
      align: 'Center',
      styleType: 'Medium',
      colour: 'Dark',
      type: 'Body4',
    },
  },
  'PendingNoButton': {
    icon: {
      asset: 'Clock',
      colour: 'IconDark',
    },
    email: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Dark',
      type: 'Body3',
    },
    status: {
      align: 'Center',
      styleType: 'Medium',
      colour: 'Dark',
      type: 'Body4',
    },
  },
};

const getProps = (type: string): TeamCardInternalProps => {
  const values: TeamCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
