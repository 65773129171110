import React from 'react';
import cx from 'classnames';

import {
  AccountMenuDropDown,
  Border,
  SpindlLogo,
} from 'spindl-shared-components';

import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import { TopNavCombinedProps } from './types';


const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    className,
    classes,
    spindlLogo,
    accountMenuDropDown,
    border,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.topNav;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.logoContainer, classes?.logoContainer)}>
          <SpindlLogo
            className={cx(styles.spindlLogo, classes?.spindlLogo)}
            {...internalProps.spindlLogo}
            {...spindlLogo}
          />
        </div>
        <AccountMenuDropDown
          className={cx(styles.accountMenuDropDown, classes?.accountMenuDropDown)}
          {...internalProps.accountMenuDropDown}
          {...accountMenuDropDown}
        />
      </div>
      <Border
        className={cx(styles.border, classes?.border)}
        {...internalProps.border}
        {...border}
      />
    </div>
  );
};

export default TopNav;
