import { useTranslation } from 'react-i18next';
import { SubNavCombinedProps } from './types';

const usePresenter = (props: SubNavCombinedProps): SubNavCombinedProps => {
  const { t } = useTranslation();

  props = {
    horizontalTabList: {
      horizontalTabItems: [{
        text: {
          value: t('left_nav.team'),
        },
        state: 'Selected',
      }],
    },
  };
  return props;
};

export default usePresenter;
