import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';

import { NotificationContainer } from 'spindl-shared-components';
import { TemplateEditorModule } from 'spindl-communication-mod';
import { DatabaseEditorModule } from 'spindl-data-mod';

import TopNav from '../../organisms/TopNav';
import { EDITOR_ROUTES } from '../../../lib/constants';
import styles from './EditorLayout.module.scss';
import usePresenter from './EditorLayout.presenter';
import getProps from './EditorLayout.props';
import { EditorLayoutCombinedProps } from './types';


const EditorLayout: React.FC<EditorLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.editorLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <TopNav
        className={cx(styles.topNav, classes?.topNav)}
        {...internalProps.topNav}
        {...topNav}
      />

      {/* Notification container used across all modules in editor-mode */}
      <NotificationContainer className={styles.notificationContainer} />

      {/* Services in editor-mode */}
      <Switch>
        <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          <Route path={EDITOR_ROUTES.communication} component={TemplateEditorModule} />
          <Route path={EDITOR_ROUTES.data} component={DatabaseEditorModule} />
        </div>
      </Switch>
    </div>
  );
};

export default EditorLayout;
