import {
  AppDropDownProps,
  BorderProps,
  VerticalTabListProps,
  TextProps,
} from 'spindl-shared-components';


type LeftNavInternalProps = {
  appDropDown?: AppDropDownProps;
  serviceList?: VerticalTabListProps;
  text?: TextProps;
  border?: BorderProps;
  settingsList?: VerticalTabListProps;
  border1?: BorderProps;
};

type LeftNavPropsValues = {
  '': LeftNavInternalProps;
};

const propValues: LeftNavPropsValues = {
  '': {
    appDropDown: {
      state: 'Collapsed',
    },
    serviceList: {
    },
    text: {
      align: 'Left',
      styleType: 'Medium',
      colour: 'Dark',
      type: 'Heading6',
    },
    border: {
      colour: 'Subdued',
      type: 'Horizontal',
      styleType: 'Default',
    },
    settingsList: {
    },
    border1: {
      colour: 'Subdued',
      type: 'Vertical',
      styleType: 'Default',
    },
  },
};

const getProps = (type: string): LeftNavInternalProps => {
  const values: LeftNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
